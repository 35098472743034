<header class="header" [ngClass]="renderAsTab ? 'header--fixed' : 'header'">
    <div class="container">
        <div class="rowx">
            <div class="header__banner four columns">
                <div class="header__logo">
                    <a class="home" href="#header"><img src="assets/images/jlpt_logo_two_colour_reverse_1.svg" alt="JLPT"></a>
                </div>
                <div class="header__strapline">
                    <p>Personal Training &amp; Coaching</p>
                </div>
            </div>
            <nav class="navigation eight columns" [class.navigation--collapsed]="enableMobileNav">
               
                <button *ngIf="enableMobileNav" class="nav-button" (click)="toggleNav()">
                    <img *ngIf="!displayMobileNav" src="assets/images/hamburger-menu-svgrepo-com.svg">
                    <img *ngIf="displayMobileNav" src="assets/images/close-svgrepo-com.svg">
                </button>

                <ul class="navigation__list" [class.navigation__list--mobile]="displayMobileNav">
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        routerLink="/register"><fa-icon [icon]="faUser"></fa-icon> Register</a>
                    </li>
                    <li class="navigation__list-item navigation__list-item--spacer">
                        |
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-about">Online</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-pricing">Pricing</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-gym">Training studio</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-profile">About</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>



<div class="register stripe">
    <div class="container">
        <div class="contact ten columns">
            <h1 class="contact__title">Register your interest</h1>
            <form>
                <form class="contact__form" action="https://submit-form.com/AqAKZn8J" method="POST">
                    <label for="EmailAddress">Email Address</label>
                    <input type="email" id="emailAddress" name="EmailAddress" placeholder="Email Address" required />
                    <label for="FirstName">First Name</label>
                    <input type="text" id="firstname" name="FirstName" placeholder="First Name" required />
                    <label for="LastName">Last Name</label>
                    <input type="text" id="lastname" name="LastName" placeholder="Last Name" required />
                    <label for="MobileNumber">Mobile Number</label>
                    <input type="tel" maxlength="15" id="mobileNumber" name="MobileNumber" placeholder="Mobile Number" required />
                    <label for="Message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        required=""></textarea>
                    <input
                        type="checkbox"
                        name="newsletter"
                        style="display:none"
                        tabindex="-1"
                        autocomplete="off"/>
                    <div class="g-recaptcha" data-sitekey="6LfeYIQgAAAAACu2hEH7CkKpRXwh4Hpz4Tm-QuRd"></div>
                    <button type="submit">Send</button>
                  </form>
            </form>
        </div>
    </div>
</div>

