<div id="header"></div>
<header class="header" [ngClass]="renderAsTab ? 'header--fixed' : 'header'">
    <div class="container">
        <div class="rowx">
            <div class="header__banner five columns">
                <div class="header__logo">
                    <a class="home" href="#header"><img src="assets/images/jlpt_logo_two_colour_reverse_1.svg" alt="JLPT"></a>
                </div>
                <div class="header__strapline">
                    <p>Personal Training &amp; Coaching</p>
                </div>
            </div>
            <nav class="navigation seven columns" [class.navigation--collapsed]="enableMobileNav">
               
                <button *ngIf="enableMobileNav" class="nav-button" (click)="toggleNav()">
                    <img *ngIf="!displayMobileNav" src="assets/images/hamburger-menu-svgrepo-com.svg">
                    <img *ngIf="displayMobileNav" src="assets/images/close-svgrepo-com.svg">
                </button>

                <ul class="navigation__list" [class.navigation__list--mobile]="displayMobileNav">
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        routerLink="/register"><fa-icon [icon]="faUser"></fa-icon> Register</a>
                    </li>
                    <li class="navigation__list-item navigation__list-item--spacer">
                        |
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-about">Online</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-pricing">Pricing</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-gym">Training studio</a>
                    </li>
                    <li class="navigation__list-item">
                        <a class="navigation__list-link" (click)="toggleNav()"
                        href="#section-profile">About</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>

<section class="section section--first section--inverse section--header" id="section-header">
    <div class="header-image">
        <img class="lazy-image" src="assets/images/main-banner-1.jpg" alt="barbell rack" loading="lazy">
    </div>
    <div class="container container--first">
        <div class="row">
            <div class="six columns jlpt-type-block jlpt-type-block--inverse jlpt-type-block--first">
                <h1 class="jlpt-type-block__heading">Perform. Persist. Prevail</h1>
                <p class="jlpt-type-block__paragraph jlpt-type-block__paragraph--soft">JLPT fitness services are derived from 13 years of experience at the highest professional level in the fitness industry.</p>
                <div class="jlpt-bars">
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                </div>
            </div>
            <div class="six columns">
                <img class="header-mugshot" src="assets/images/james-photo.png" alt="James Luscott Personal Trainer" loading="lazy">
            </div>

            <div class="six columns heart-rate">
                <img class="heart-rate__img" src="assets/images/heart-rate.svg" alt="JLPT">
            </div>
        </div>
        
        <div #socialTab id="marker-header-floating-tab"></div>
        <div class="twelve columns stripe"
            [ngStyle]="{'width': renderAsTab ? '300px' : '100%'}"
            [ngClass]="renderAsTab ? 'stripe--header-floating-tab' : 'stripe--header'">
            <div class="specials">
                <button class="jlpt-button" routerLink="/register">Book your free 30 min consultation</button>
                <ul class="social">
                    <li>
                        <a href="https://instagram.com/jlptpersonaltraining" class="social__icon social__icon--first">
                            <fa-icon [icon]="faEye"></fa-icon><span class="social__icon-label">Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://facebook.com/JL-Personal-Training-and-Coaching-105745385344670" class="social__icon">
                            <fa-icon [icon]="faThumbsUp"></fa-icon><span class="social__icon-label">Facebook</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="section section--inverse section--profile" id="section-profile">
    <div class="jlpt-profile-backdrop stripe"></div>
    <div class="container">
        <div class="row">
            <div class="six columns jlpt-type-block">
                <h1 class="jlpt-type-block__heading">introducing JLPT</h1>
                <p class="jlpt-type-block__paragraph">JLPT fitness services are derived from 13 years of experience at the highest professional level in the fitness industry.</p>
                <!-- desktop only -->
                <div class="online-package">
                    <div class="jlpt-bars">
                    </div>
                    <div class="jlpt-type-block jlpt-type-block--inverse footer-logo">
                        <img style="width: 25%;" class="footer-logo__img" src="assets/images/jlpt_logo_two_colour_reverse_1.svg" alt="JLPT">
                    </div>
                    <h3>James Luscott <span>Personal Training &amp; Coaching</span></h3>
                    <p class="jlpt-type-block__list">
                        Learning over the years how gyms can be a very daunting and scary environment for people gave me the idea to have my very own one to one studio to help clients feel comfortable and gain 100% progression and attention from sessions.
                    </p>
                </div>

                <div class="online-package">
                    <div class="jlpt-bars">
                    </div>
                    <h3>During my time as a trainer, I have obtained a vast wealth of knowledge and self-development working in the fitness industry.</h3>
                    <p class="jlpt-type-block__list">
                        I've had the privilege to focus this energy into what has become a transformative way of life and not just a career.
                    </p>
                </div>
            </div>

            <div class="six columns jlpt-type-block">
                <img class="mug-sml" src="assets/images/james-photo.png" alt="James Luscott Personal Trainer" loading="lazy">

                <div class="online-package speech">
                    <div class="jlpt-bars">
                    </div>
                    <h3>My Priority is to convert your goals into personal results that transform your life for the better.</h3>
                    <p class="jlpt-type-block__list">
                        I will provide this to you with the highest-quality service that is 
                        paramount to my business. All these factors are what JLPT is built upon. So, no matter how big or small these may be, you can rely on me to get you over the finish line.
                    </p>
                    <h3>Perform. Persist. Prevail</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section section--inverse section--gym" id="section-gym">
    <div class="stripe stripe--absolute"></div>
    <div class="container">
        <div class="row flex-row">
            <div class="six columns grid-flex-col-center">
                <div class="jlpt-image-grid">
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0781_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0789_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0797_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0808_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0810_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                    <div class="jlpt-image-grid__image">
                        <img src="assets/images/image-grid/IMG_0816_1_1-1.jpg" alt="James Luscott Personal Trainer" loading="lazy">
                    </div>
                </div>
            </div>
            <div class="six columns jlpt-type-block jlpt-type-block--inverse">
                <h1 class="jlpt-type-block__heading">The Training Studio</h1>
                <p class="jlpt-type-block__paragraph">
                    Get exclusive access to an independent, one to one home training studio based in Kent. Have it all to yourself every session.
                     You can get to work with whatever you need without waiting on others to return it to the rack.
                </p>
                <div class="jlpt-bars jlpt-bars--underline">
                    <div class="jlpt-bar jlpt-bar--single"></div>
                </div>
                <div class="jlpt-type-block__paragraph jlpt-type-block__paragraph--small">
                    <ul class="jlpt-type-block__list jlpt-type-block__list--inline">
                        <li>Squat Rack</li>
                        <li>Olympic Bar</li>
                        <li>Compact Bar &#47; Women&#39;s Bar</li>
                        <li>Hex Bar</li>
                        <li>EZ Bar</li>
                        <li>Adjustable Bench</li>
                        <li>230KG in Bumpber Plates</li>
                        <li>3-20KG Hex Dumbells</li>
                        <li>TRX Suspension Trainer</li>
                        <li>Plate Loading Pulley System</li>
                        <li>Kettlebells 8-22KG</li>
                        <li>Weighted Chains</li>
                        <li>Slam Balls 5KG-20KG</li>
                        <li>Battle Ropes</li>
                        <li>Landmine</li>
                        <li>Multiple Landmine and Pulley Attachments</li>
                        <li>Resistance Bands</li>
                        <li>Mobility Equipment</li>
                    </ul>
                </div>
                <button class="jlpt-button" routerLink="/register">Sign up</button>
            </div>
        </div>
    </div>
</section>

<section class="section section--pricing" id="section-pricing">
    <div class="container">
        <div class="row">
            <div class="six columns">
                <div class="jlpt-type-block">
                    <div class="jlpt-type-block__container">
                        <h1 class="jlpt-type-block__heading">Training Studio Price List</h1>
                        <p class="jlpt-type-block__paragraph">JLPT fitness services are derived from 13 years of experience at the highest professional level in the fitness industry.</p>
                        <div class="jlpt-bars">
                            <div class="jlpt-bar"></div>
                            <div class="jlpt-bar"></div>
                            <div class="jlpt-bar"></div>
                        </div>

                        <button class="jlpt-button" routerLink="/register">Book your free 30 min consultation</button>
                    </div>
                </div>
                <table>
                    <thead>
                      <tr>
                        <th colspan="3">
                            30 Minute Sessions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="one-to-one" rowspan="4">1:1</th>
                        </tr>
                       <tr>
                        <td>Single session (HIIT)</td>
                        <td>£35</td>
                      </tr>
                       <tr>
                        <td>10 Sessions</td>
                        <td>£350</td>
                      </tr>
                       <tr>
                        <td>20 Sessions</td>
                        <td>£690 <span class="discount-pill">£10 Discount</span></td>
                      </tr>

                      <tr>
                        <th rowspan="4">Duo</th>
                    </tr>
                   <tr>
                    <td>Single session (HIIT)</td>
                    <td>£50 (£25 per person)</td>
                  </tr>
                   <tr>
                    <td>10 Sessions</td>
                    <td>£500</td>
                  </tr>
                   <tr>
                    <td>20 Sessions</td>
                    <td>£990 <span class="discount-pill">£10 Discount</span></td>
                  </tr>
                    </tbody>
                </table>
            </div>

            <div class="six columns">
                <table>
                    <thead>
                      <tr colspan="">
                        <th colspan="3">45 Minute Sessions</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="one-to-one" rowspan="4">1:1</th>
                        </tr>
                       <tr>
                        <td>Single session</td>
                        <td>£40</td>
                      </tr>
                       <tr>
                        <td>10 Sessions</td>
                        <td>£400</td>
                      </tr>
                       <tr>
                        <td>20 Sessions</td>
                        <td>£780 <span class="discount-pill">£20 Discount</span></td>
                      </tr>

                      <tr>
                        <th rowspan="4">Duo</th>
                    </tr>
                   <tr>
                    <td>Single session</td>
                    <td>£55 (£27.50 per person)</td>
                  </tr>
                   <tr>
                    <td>10 Sessions</td>
                    <td>£550</td>
                  </tr>
                   <tr>
                    <td>20 Sessions</td>
                    <td>£1,085 <span class="discount-pill">£15 Discount</span></td>
                  </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                      <tr>
                        <th colspan="3">1 Hour Sessions</th>
                      </tr>
                    </thead>
                    <tbody>
                     <tr>
                        <th class="one-to-one" rowspan="4">1:1</th>
                    </tr>
                      <tr>
                        <td>Single Session</td>
                        <td>£45</td>
                      </tr>
                       <tr>
                        <td>10 Sessions</td>
                        <td>£450</td>
                      </tr>
                       <tr>
                        <td>20 Sessions</td>
                        <td>£870 <span class="discount-pill">£30 Discount</span></td>
                      </tr>

                      <tr>
                        <th rowspan="4">Duo</th>
                    </tr>
                      <tr>
                        <td>Single Session</td>
                        <td>£65 (£32.50 per person)</td>
                      </tr>
                       <tr>
                        <td>10 Sessions</td>
                        <td>£650</td>
                      </tr>
                       <tr>
                        <td>20 Sessions</td>
                        <td>£1,280 <span class="discount-pill">£20 Discount</span></td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="jlpt-type-block">
            <div class="jlpt-type-block__container">
                Pay as you go options also available. Please get in touch for further details.
            </div>
        </div>
    </div>
</section>

<section class="section section--about" id="section-about">
    <div class="container">
        <div class="row row--join-bottom">
            <div class="six columns jlpt-type-block jlpt-type-block--inverse">
                <h1 class="jlpt-type-block__heading">Train whenever, wherever...</h1>
                <p class="jlpt-type-block__paragraph">Get access to your personalised online training package with the support you need at the click of a button.</p>
            </div>
        </div>
        <div class="css-grid css-grid--online-packages row row--join-top">
            <div class="online-package">
                <div class="online-package__price">£195</div>
                <div class="jlpt-bars">
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                </div>
                <h3>6 Week <span>Online Training Package</span></h3>
                <p>Includes:</p>
                <ul class="jlpt-type-block__list">
                <li>Online 45-minute consultation</li>
                <li>Bespoke training plan</li>
                <li>Customised meal plan</li>
                <li>Unlimited message support with James</li>
                </ul>
            </div>
            <div class="online-package">
                <div class="online-package__price">£295</div>
                <div class="jlpt-bars">
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                </div>
                <h3>12 Week <span>Online Training Package</span></h3>
                <p>Includes everything in 6 week plus:</p>
                <ul class="jlpt-type-block__list">
                <li>Access to exercise video library (coming soon)</li>
                <li>Free JLPT T- shirt</li>
                <li>Pay in instalments 2x £147.50 every 6 weeks</li>
                </ul>
            </div>
            <div class="online-package">
                <div class="online-package__price">£475</div>
                <div class="jlpt-bars">
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                    <div class="jlpt-bar"></div>
                </div>
                <h3>24 Week <span>Online Training Package</span></h3>
                <p>Includes everything in 12 week plus:</p>
                <ul class="jlpt-type-block__list">
                <li>Pay in instalments 4x £112.50 every 6 weeks</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="section section--inverse section--reviews" id="section-reviews">
    <div class="stripe stripe--absolute"></div>
    <div class="container">
        <div class="row" style="display: flex;">
            <div class="twelve columns jlpt-type-block jlpt-type-block--inverse">
                <h1 class="jlpt-type-block__heading">Reviews</h1>
                <div class="reviews-outer">
                    <div class="reviews-container">
                        <div class="reviews">
                            <ul class="review-list">
                                <li *ngFor="let review of reviews" class="review-list__item">
                                    <div class="review" [class.review--active]="review.visible">
                                        <p class="review__long">{{review.review}}</p>
                                        <p class="review__author">{{review.author}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
        
                        <div class="jlpt-bars jlpt-bars--underline">
                            <div *ngFor="let review of reviews; index as i"
                                class="jlpt-bar jlpt-bar--single jlpt-bar--button"
                                [class.jlpt-bar--inactive]="!review.visible"(click)="setReview(i)"></div>
                        </div>
                    </div>
                    <div class="two columns controls"><button (click)="cycleReviews('forward')">></button></div>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="section section--footer">
    <div class="container">
        <div class="row">
            <div class="six columns jlpt-type-block jlpt-type-block--inverse">
                <div class="footer-logo">
                    <img class="footer-logo__img" src="assets/images/jlpt_logo_two_colour_reverse_1.svg" alt="JLPT">
                </div>
                <small>&copy; Copyright JLPT 2022</small>
            </div>
        </div>
    </div>
</footer>
