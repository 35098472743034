import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { faCircleXmark, faArrowAltCircleRight, faUser, faHourglass, faThumbsUp, faComment, faEye } from '@fortawesome/free-regular-svg-icons';
import { Review } from './review';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public reviews: Review[] = [];
  public showReviewIndex: number = 0;
  public overlay = false;
  public renderAsTab: boolean;
  public displayMobileNav: boolean;
  public enableMobileNav: boolean;

  public faCircleXmark = faCircleXmark;
  public faUser = faUser;
  public faArrowAltCircleRight = faArrowAltCircleRight;
  public faHourglass = faHourglass;
  public faThumbsUp = faThumbsUp;
  public faComment = faComment;
  public faEye = faEye;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.reviews = [
      {author: 'Wojciech Witoszynski', review: `I signed up for PT sessions with James just over two years ago and I haven't looked back since. We started off with face to face classes and then switched to online when the lockdown started. Whatever the format was, James has always been professional, friendly and he'd always make sure there was enough variety in classes to keep them fun. His classes had a huge impact in helping me stay fit while spending a significant amount of time each day in front of a computer - I can see a huge difference in weight, posture and overall well-being. I would definitely recommend James!`, visible: true},
      {author: 'Stephen Norton', review: `James is the ultimate personal trainer - he develops a unique programme tailored to your needs which challenges you at every step and focuses your mind on how to get fit and healthy, and how to work towards fitness. James motivates me to think positively about exercise: he challenges me and pushes me harder, and provides a truly bespoke approach to fitness as no two training sessions are ever the same. James ensures that we work towards achievable goals and then we go further - and we have fun doing it!`, visible: false},
      {author: 'Melissa Ramos', review: `James has been my trainer for the past three years and for the first time in my life I have begun to enjoy working out. He is incredibly knowledgeable about fitness and nutrition and has tailored my workout programme to help me achieve my goal of getting stronger and fitter. Most importantly, he is cheerful, patient and encouraging and has enabled me to develop the self-discipline and motivation to exercise on a regular basis.`, visible: false},
      {author: 'Ally Miller', review: `James has been my PT for 7 years. He has worked with me not only to achieve my goals but also to develop them. He has pushed me further than I thought possible in a friendly and encouraging way. He works with me to find an interesting routine, and he gets the right balance of variety and consistency. And above all, he's really nice and fun to work with!`, visible: false}
    ]
  }

  @ViewChild('socialTab')
  public socialTab: ElementRef;

  ngOnInit(): void {
    this.breakpointObserver.observe('(max-width: 999px)').subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.enableMobileNav = true;
      } else {
        this.enableMobileNav = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.registerIntersectionObserver();
  }

  public cycleReviews(direction: 'forward' | 'back') {
    for (let i = 0; i < this.reviews.length; i++) {
        this.reviews[i].visible = false;
    }

    let showIndex;

    if (direction === 'forward') {
      let newIndex = this.showReviewIndex + 1
      if (newIndex < this.reviews.length) {
        showIndex = newIndex;
      } else {
        showIndex = 0;
      }
    
      this.reviews[showIndex].visible = true;
      this.showReviewIndex = showIndex;

    }
  }

  public setReview(index: number) {
    for (let i = 0; i < this.reviews.length; i++) {
      this.reviews[i].visible = false;
    }

    this.reviews[index].visible = true;
  }

  public toggleOverlay(): void {
    this.overlay = !this.overlay;
  }

  public toggleNav() {
    this.displayMobileNav = !this.displayMobileNav; 
  }

  public hideNav () {
    this.displayMobileNav = false;
  }

  public registerIntersectionObserver () {
    if ("IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
          
        let observer = new IntersectionObserver(entries => {
            if (entries[0].boundingClientRect.y < 0) {
              //document.body.classList.add("header-not-at-top");
              this.renderAsTab = true;
            } else {
              //document.body.classList.remove("header-not-at-top");
              this.renderAsTab = false;
            }
          });
          observer.observe(this.socialTab.nativeElement);
    }
  }

}
