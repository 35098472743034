import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { faCircleXmark, faArrowAltCircleRight, faUser, faHourglass, faThumbsUp, faComment } from '@fortawesome/free-regular-svg-icons';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public renderAsTab: boolean;
  public displayMobileNav: boolean;
  public enableMobileNav: boolean;
  public fadeIn = false;

  public faUser = faUser;

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver.observe('(max-width: 999px)').subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.enableMobileNav = true;
      } else {
        this.enableMobileNav = false;
      }
    });
  }


  public toggleNav() {
    this.displayMobileNav = !this.displayMobileNav; 
  }

  public hideNav () {
    this.displayMobileNav = false;
  }

}
